import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";

import firebase from "firebase/app";
import "firebase/auth";

const Utility = {
  // mixins: [FirebaseActions],
  computed: {
    ...mapGetters(["appConfig", "currentBrowser", "subscriberid"]),
  },
  methods: {
    ...mapActions(["fcmMessagingUnsubscribeToTopic"]),
    ...mapMutations(["setFirebaseUserUID"]),

    validName(name) {
      var regEx = /^[a-zA-Z ]{2,32}$/;
      return regEx.test(name);
    },
    validateName(name) {
      this.formError = null;
      if (!name) {
        this.formError = this.$t("Name Required");
        return false;
      }
      // else if (!this.validName(name)) {
      //   this.formError = this.$t("Name should have minimum 2 and maximum 32 characters");
      //   return false;
      // }
      return true;
    },
    validEmail(email) {
      /* eslint-disable */
      let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regx.test(String(email).toLowerCase());
    },
    validateEmail(email) {
      this.formError = null;
      if (!email) {
        this.formError = this.$t("Please fill out this field");
        return false;
      } else if (!this.validEmail(email)) {
        this.formError = this.$t("Please enter only Latin letters or numbers, Ex: Abc,123");
        return false;
      }
      return true;
    },

    validateCountryCode() {
      this.formError = null;
      if(!this.country) {
        this.formError = "The Country code could not be fetched! Please try after sometime.";
        return false;
      }

      return true;
    },

    validateMobileno(mobile) {
      this.formError = null;
      if (!mobile) {
        this.formError = this.$t("Mobileno Required");
        return false;
      }
      return true;
    },
    validateEmailOrMobile(userInput) {
      this.formError = null;
      if (!userInput) {
        this.formError = this.$t("Email or Mobile required");
        return false;
      }
      return true;
    },
    validatePassword(password) {
      this.formError = null;
      if (!password) {
        this.formError = this.$t("Password Required!");
        return false;
      } else if (password.length < 6) {
        this.formError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.formError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },
    validateOldPassword(password) {
      if (!password) {
        this.formError = this.$t("Old Password Required!");
        return false;
      } else if (password.length < 6) {
        this.formError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.formError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },
    validateDOB(age) {
      this.formError = null;
      if (!age) {
        this.formError = this.$t("Age is Mandatory");
        return false;
      } else if (!this.appConfig.featureEnabled.kidsMode && this.kidsProfile) {
        // this.formError = "Age Should Be Greater Than 13";
        this.formError = this.$t("Age range is mandatory");

        return false;
      }
      return true;
    },
    validateMaxLength(amt, len) {
      if (amt.length >= len) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    pinFunctionality(IdName) {
      var container = IdName;
      let check = true;
      container.onkeypress = function(e) {
        if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 43 || e.keyCode == 16) || e.keyCode == 32) {
          // e.returnValue = false;
          check = false;
          e.preventDefault();

          return;
        } else {
          check = true;
        }
      };
      if (check) {
        container.onkeyup = function(e) {
          var target = e.srcElement || e.target;
          var maxLength = parseInt(target.attributes["maxlength"].value, 10);
          var myLength = target.value.length;
          if (myLength >= maxLength) {
            var next = target;
            while ((next = next.nextElementSibling)) {
              if (next == null) break;
              if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
              }
            }
          }
          // Move to previous field if empty (user pressed backspace)
          else if (myLength === 0) {
            var previous = target;
            while ((previous = previous.previousElementSibling)) {
              if (previous == null) break;
              if (previous.tagName.toLowerCase() === "input") {
                previous.focus();
                break;
              }
            }
          }
        };
      }
    },
    toggleEye(_event) {
      // Get the target element
      let target = _event.target;
      // Get the container div of the input field
      let parentNode = target.parentNode.parentNode;
      // Get the input element from it
     
      let inputElm = parentNode.getElementsByTagName("input")[0];

      if(inputElm === undefined){
        inputElm = parentNode.parentNode.getElementsByTagName("input")[0];
      }

      // Now change the type to text if password or vice-versa and change the icon
      if (inputElm.type === "password") {
        inputElm.type = "text";
        // Change the icon
        target.src = this.eyeClose;
      } else {
        inputElm.type = "password";
        // Change the icon
        target.src = this.eyeopen;
      }
    },
    collectionsToArray(collections) {
      let arr = [];
      for (var i in collections) {
        let obj = {
          ...collections[i],
        };
        arr.push(obj);
      }
      return arr;
    },
    logoutCB() {
      eventBus.$emit("enableOverlayLoader", true);
      try {
        const subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));
        //removing subscriber details from localstorage.
        localStorage.removeItem("subscriberDetails");
        localStorage.removeItem("isMultipleProfileEnabled");
        //removing config details from localstorage.
        localStorage.removeItem("appConfig");
        //removing loginType from localstorage.
        localStorage.removeItem("loginType");
        localStorage.removeItem("paymentGetwaySaveUrl");

        localStorage.removeItem("sessionToken");

        localStorage.removeItem("currentProfileIdAdminCheck");

      
        localStorage.removeItem("login-payload");

        localStorage.removeItem("currentProfileId");

        localStorage.removeItem("subscriberProfileDetails");

        localStorage.removeItem("subscriptionDetails");

        localStorage.removeItem("offerCoupon");
        localStorage.removeItem("profile-list");
        localStorage.removeItem("refreshtoken");
        localStorage.removeItem("qualitylist");


        
        clearInterval(window.myIntervalreFreshToken);

        localStorage.removeItem("parentalControlEnabled")
        
        //commiting appconfig state after removed from localstorage.
        store.commit("commitappconfig");
        //commiting subscriberdetails state after removed from localstorage.
        store.commit("commitsubscriberdetails");
        //commiting setToken state after removed from localstorage.
        store.commit("setToken");
        //routing back to home page.

        // Unsubscribe from USER ID
        this.fcmMessagingUnsubscribeToTopic(this.subscriberid);

        // Unsubscribe from Country
        this.fcmMessagingUnsubscribeToTopic(subscriberDetails.country);

        eventBus.$emit("enableOverlayLoader", false);

        setTimeout(() => {
          location.reload();
        }, 1000)

        firebase
            .auth()
            .signOut()
            .then(() => {});

            this.firebaseRemoveUser()
                .then(() => {
                  firebase
                    .auth()
                    .signOut()
                    .then((data) => {});
                });

                this.setFirebaseUserUID(null);

              

      //   setTimeout(() => {
      //     firebase
      //       .auth()
      //       .signOut()
      //       .then(() => {
      //         this.$router.push("/");
      //         eventBus.$emit("enableOverlayLoader", false);

      //         this.$router.go(0);
      //       })
      //       .catch((err) => {
      //         this.$router.push("/");
      //         eventBus.$emit("enableOverlayLoader", false);

      //         this.setFirebaseUserUID(null);

      //         this.$router.go(0);
      //       });
      //   }, 1500);

      //   this.firebaseRemoveUser()
      //     .then(() => {
      //       firebase
      //         .auth()
      //         .signOut()
      //         .then((data) => {
      //           this.$router.push("/");
      //           eventBus.$emit("enableOverlayLoader", false);

      //           this.setFirebaseUserUID(null);

      //           this.$router.go(0);
      //           // location.reload();
      //         })
      //         .catch(() => {
      //           this.$router.push("/");
      //           eventBus.$emit("enableOverlayLoader", false);

      //           this.setFirebaseUserUID(null);

      //           this.$router.go(0);

      //           // location.reload();
      //         });
      //     })
      //     .catch(() => {
      //       eventBus.$emit("enableOverlayLoader", false);

      //       this.setFirebaseUserUID(null);

      //       this.$router.go("/");

      //       // location.reload();
      //     });
      } catch (err) {
        // // this.$router.push("/");
        eventBus.$emit("enableOverlayLoader", false);

        // location.reload();

        // // this.$router.go("/");
      }
    },
    snackbarMessage(message, data) {
      let snack = {
        status: data,
        message: message,
      };
      eventBus.$emit("snackBarMessage", snack);
      return false;
    },
    displayDateFormat(val) {
      let convert = null;

      if (val) {
        convert = new Date(val);
      } else {
        convert = new Date();
      }
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      let currentDatetime = convert;

      let date = currentDatetime.getDate();

      if (date < 10) {
        date = "0" + date;
      }

      let d = date + " " + months[currentDatetime.getMonth()] + " " + currentDatetime.getFullYear();
      return d;
    },
    getCurrentDate(age) {
      let d = new Date();
      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

      let dd = d.getDate();
      let mm = months[d.getMonth()];
      let yyyy = d.getFullYear();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + (mm + 1);

      if (age) yyyy = yyyy - age;

      // return dd + " " + mm + " " + yyyy;

      return yyyy + "-" + mm + "-" + dd;
    },

    attachFile(event) {
      let uploadedFile = event.target.files[0];
      // let imageSize = Math.round(uploadedFile.size / 1000);
      // if (imageSize > 1000) {
      //   this.formError = "Image size should less than 1 MB";
      //   return;
      // }

      this.imagesUploadedArr.forEach((image) => {
        if (image.name === uploadedFile.name) {
          this.formError = this.$t("Same file is already attached!");
        }
      });

      if (this.formError) {
        event.target.value = null;
        return;
      } else {
        this.imagesUploadedArr.push(uploadedFile);
        event.target.value = null;
      }
    },

    getCategoryName(category) {
      let categoryName;

      this.appConfig.ticketCategories.forEach((ele) => {
        if (ele.apiCategory === category) {
          categoryName = this.localDisplayLang === "ara" ? ele.categoryTitle.ar : ele.categoryTitle.en;
        }
      });

      return categoryName;
    },

    getDate(currentDate) {
      if (currentDate) {
        return new Date(currentDate);
      } else {
        return new Date();
      }
    },

    getTodaysDate() {
      return new Date(Date.now());
    },

    getDateForPlayback(currentDate) {
      if (currentDate) {
        return new Date(
          `${new Date(currentDate).getFullYear()}/${new Date(currentDate).getMonth() + 1}/${new Date(
            currentDate
          ).getDate()} 23:59:59 UTC`
        );
      } else {
        return false;
      }
    },

    calcTimeFormat(val) {
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") +  this.$t("hr") : "") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    //fetching suitable content_details from each content object
    fetchContentDetails(content_details) {
      let temporaryVideoTag = document.createElement("video");

      let isAndroid = navigator.userAgent.includes("Android");

      let index = content_details.findIndex((element) => {
        if (!isAndroid && temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl") != "") {
          return element.drmscheme[0] === "FAIRPLAY";
        } else {
          return element.drmscheme[0] === "WIDEVINE";
        }
      });

      if (index === -1) {
        return content_details[
          content_details.findIndex((element) => {
            return element.drmscheme[0] === "NONE";
          })
        ];
      } else if (index > -1) {
        return content_details[index];
      }
    },
    //fetching suitable availability from availability set.
    fetchAvailabilityId(content_details) {
      content_details.availabilityset.forEach((availability) => {
        let index = this.availabilities.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (index > -1) {
          this.pricemodel.push(this.availabilities[index].pricemodel);
          this.filteredAvailabilities.push(this.availabilities[index]);
        }
      });
    },
    //check availability from different places.
    checkAvailability(content) {
      this.filteredAvailabilities = [];
      if (content.hasOwnProperty("contentdetails") && content.contentdetails.length > 0) {
        let content_details = this.fetchContentDetails(content.contentdetails);

        let isContentPlayable = false;

        this.fetchAvailabilityId(content_details);

        this.filteredAvailabilities.forEach((element) => {
          //check avalabilty belongs to subscribed list.
          if (element.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.forEach((subscription) => {
                let value = subscription.availabilityset.includes(element.availabilityid);
                if (value && !isContentPlayable) {
                  content.isDrmContent = true;
                  content.videoType = "Content";
                  content.playback_details = {
                    packageid: content_details.packageid,
                    availabilityid: element.availabilityid,
                    drmscheme: content_details.drmscheme[0],
                  };
                  isContentPlayable = true;
                }
              });
            }
          }
          //check availability belongs to purchased list.
          else if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
            if (this.subscriberid && this.purchases.length > 0) {
              //write purchase check logic.
              this.purchases.forEach((purchase) => {
                if (purchase.availabilityid === element.availabilityid && content.objectid === purchase.objectid) {
                  if (!isContentPlayable) {
                    console.log("matched with purchase");
                    content.isDrmContent = true;
                    content.videoType = "Content";
                    content.playback_details = {
                      packageid: content_details.packageid,
                      availabilityid: element.availabilityid,
                      drmscheme: content_details.drmscheme[0],
                    };
                    isContentPlayable = true;
                  }
                }
              });
            }
          }
          //check availability belongs to free or adbased pricemodel.
          else if (element.pricemodel === "FREE" || element.pricemodel === "ADBASED") {
            if (!isContentPlayable) {
              content.isDrmContent = true;
              content.videoType = "Content";
              //creating this playback_details for content playback.
              content.playback_details = {
                packageid: content_details.packageid,
                availabilityid: element.availabilityid,
                drmscheme: content_details.drmscheme[0],
              };
            }
          }
        });
      } else if (content.hasOwnProperty("trailer") && content.trailer.length > 0) {
        content.isDrmContent = true;
        content.videoType = "Trailer";
      }

      if (content.videoType) {
        let payload = {
          content: content,
          state: true,
        };
        // eventBus.$emit("togglePlayer", payload);
      } else {
        let payload = {};

        //check for filteredAvailabilities length.
        if (this.filteredAvailabilities && this.filteredAvailabilities.length === 1) {
          this.filteredAvailabilities.forEach((element) => {
            if (element.pricemodel === "PAID" || element.pricemodel === "RENTAL") {
              payload.listType = "onlyPurchase";
            } else if (element.pricemodel === "PLAN") {
              payload.listType = "onlySubscribe";
            }
          });
        } else if (this.filteredAvailabilities && this.filteredAvailabilities.length > 1) {
          payload.listType = "Subscribe/Purchase";
        }

        payload.availabilities = this.filteredAvailabilities;
        payload.content = content;
        eventBus.$emit("subscribePopup", payload);

        //here write logic for subscription or purchasing a content.
      }
    },

    getDeviceOS() {
      let deviceOS = "";

      if (navigator.userAgent.indexOf("Win") != -1) {
        deviceOS = "WINDOWS";
      }

      if (navigator.userAgent.indexOf("Macintosh") != -1) {
        deviceOS = "MACOS";
      }

      if (navigator.userAgent.indexOf("Linux") != -1) {
        deviceOS = "LINUX";
      }

      if (navigator.userAgent.indexOf("Android") != -1) {
        deviceOS = "ANDROID";
      }

      if (navigator.userAgent.indexOf("like Mac") != -1) {
        deviceOS = "MACOS";
      }

      if(!deviceOS) {
        deviceOS = "OTHER";
      }
      return deviceOS;
    },
    getFreeTrial() {
      console.log("get free trial called");
      let free_trial = "";
      if (this.paymentDetail && this.paymentDetail.detail) {
        if (this.paymentDetail.detail.trialperioddays > 1) {
          free_trial = true;
        } else {
          free_trial = false;
        }
      }
      return free_trial;
    },
    checkSource() {
      let source;
      if (this.$route.name == "Home") {
        source = "Home-Subscriptions";
      } else {
        source = "Profile";
      }
      return source;
    },
    getPlatform() {
      let platform;
      let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

      if (!isMobile) {
        const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

        if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
          isMobile = true;
        }
      }
      if (isMobile) {
        platform = "MobWeb";
      } else {
        platform = "Web";
      }
      return platform;
    },

    getPlatformType() {
      var unknown = "-";

      // browser
      var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browser = navigator.appName;
      var version = "" + parseFloat(navigator.appVersion);
      var majorVersion = parseInt(navigator.appVersion, 10);
      var nameOffset, verOffset, ix;

      // Opera
      if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf("OPR")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 4);
      }
      // Edge
      else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
        browser = "Microsoft Edge";
        version = nAgt.substring(verOffset + 5);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browser = "Chrome";
        version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browser = "Safari";
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browser = "Firefox";
        version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf("Trident/") != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(nAgt.indexOf("rv:") + 3);
      }
      // Other browsers
      else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
        }
      }
      // trim the version string
      if ((ix = version.indexOf(";")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(" ")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(")")) != -1) version = version.substring(0, ix);

      majorVersion = parseInt("" + version, 10);
      if (isNaN(majorVersion)) {
        version = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      // mobile version
      var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

      // cookie
      var cookieEnabled = navigator.cookieEnabled ? true : false;

      if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie") != -1 ? true : false;
      }

      // system
      var os = unknown;
      var clientStrings = [
        { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
        { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
        { s: "Windows Vista", r: /Windows NT 6.0/ },
        { s: "Windows Server 2003", r: /Windows NT 5.2/ },
        { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
        { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
        { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
        { s: "Windows 98", r: /(Windows 98|Win98)/ },
        { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
        { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: "Windows CE", r: /Windows CE/ },
        { s: "Windows 3.11", r: /Win16/ },
        { s: "Android", r: /Android/ },
        { s: "Open BSD", r: /OpenBSD/ },
        { s: "Sun OS", r: /SunOS/ },
        { s: "Linux", r: /(Linux|X11)/ },
        { s: "iOS", r: /(iPhone|iPad|iPod)/ },
        { s: "Mac OS X", r: /Mac OS X/ },
        { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: "QNX", r: /QNX/ },
        { s: "UNIX", r: /UNIX/ },
        { s: "BeOS", r: /BeOS/ },
        { s: "OS/2", r: /OS\/2/ },
        { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
      ];
      for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }

      var osVersion = unknown;

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = "Windows";
      }

      switch (os) {
        case "Mac OS X":
          osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
          break;

        case "Android":
          osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
          break;

        case "iOS":
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
          break;
      }

      // NOTE added device make model
      var nAgt = undefined;
      nAgt = navigator.userAgent;
      let device = "";
      let device_make = null;
      let device_model = null;
      if (nAgt) {
        // Nexus Phones
        if (nAgt.indexOf("Nexus") !== -1) {
          device = nAgt.substring(nAgt.indexOf("Nexus"), nAgt.indexOf(")"));
          device_make = "LG";
          device_model = device.substr(0, device.indexOf("Build"));
        }
        // Nokia Lumia Phones
        else if (nAgt.indexOf("Lumia") !== -1) {
          device_make = "Nokia";
          device = device_make + " " + nAgt.substring(nAgt.indexOf("Lumia"), nAgt.indexOf(")"));
          device_model = device;
        }
        // Nokia N-Series
        else if (nAgt.indexOf("NokiaN") !== -1) {
          device = nAgt.substring(nAgt.indexOf("NokiaN"), nAgt.indexOf(")"));
          device_make = "Nokia";
          device_model = device_make + " " + device.substring(device_make.length);
        }
        // iPad
        else if (nAgt.indexOf("iPad") !== -1) {
          device = nAgt.substring(nAgt.indexOf("iPad"), nAgt.indexOf(";"));
          device_make = "Apple";
          device_model = device_make + " " + device;
        }
        // iPhone
        else if (nAgt.indexOf("iPhone") !== -1) {
          device = nAgt.substring(nAgt.indexOf("iPhone"), nAgt.indexOf(";"));
          device_make = "Apple";
          device_model = device_make + " " + device;
        }
        // Pixel
        else if (nAgt.indexOf("Pixel") !== -1) {
          device = nAgt.substring(nAgt.indexOf("Pixel"), nAgt.indexOf(")"));
          device_make = "Google";
          // device_model = device.substr(0, device.indexOf(' ', device.indexOf(' ') + 1));
          device_model = device.substr(0, device.indexOf("Build"));
        }
        // Blackberry
        if (nAgt.indexOf("BB") !== -1) {
          device = nAgt.substring(nAgt.indexOf("BB"), nAgt.indexOf(";"));
          device_make = "BlackBerry";
          device_model = device_make + " " + device;
        }
        // Blackberry Playbook
        else if (nAgt.indexOf("PlayBook") !== -1) {
          device = nAgt.substring(nAgt.indexOf("PlayBook"), nAgt.indexOf(";"));
          device_make = "BlackBerry";
          device_model = device_make + " " + device;
        }
        // Kindle Fire
        if (nAgt.indexOf("KFAPWI") !== -1) {
          device = nAgt.substring(nAgt.indexOf("KFAPWI"), nAgt.indexOf(")"));
          device_make = "Kindle";
          device_model = device_make + " " + device.substr(0, device.indexOf("Build"));
        }
        // LG
        else if (nAgt.indexOf("LG") !== -1) {
          device = nAgt.substring(nAgt.indexOf("LG"), nAgt.indexOf(")"));
          device_make = "LG";
          device_model = device_make + " " + device.substr(0, device.indexOf("Build"));
        }
        // other mobile model
        else {
          var split_mobile_model = [];
          var split_mobile_model_again = [];
          if (nAgt.indexOf("Build") !== -1) {
            split_mobile_model = nAgt.split("Build")[0];
            split_mobile_model_again = split_mobile_model.split(";");
            device_model = split_mobile_model_again[split_mobile_model_again.length - 1];
            device_model = device_model.trim();
          }
        }
      }
      // console.log("mixins.js|device_model",device_model);
      // end of device make model

      // flash (you'll need to include swfobject)
      /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
      var flashVersion = "no check";
      if (typeof swfobject != "undefined") {
        var fv = swfobject.getFlashPlayerVersion();
        if (fv.major > 0) {
          flashVersion = fv.major + "." + fv.minor + " r" + fv.release;
        } else {
          flashVersion = unknown;
        }
      }

      return {
        // screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        flashVersion: flashVersion,
        device_model: device_model,
      };
    },

    getCurrentTimeFrame() {
      let now = new Date();
      // let utcTime = new Date(now.toUTCString());
      // let hours = utcTime.getHours();

      let hours = now.getUTCHours();

      let properTime;

      if (hours >= 0 && hours < 3) {
        properTime = "12am-3am";
      } else if (hours >= 3 && hours < 6) {
        properTime = "3am-6am";
      } else if (hours >= 6 && hours < 9) {
        properTime = "6am-9am";
      } else if (hours >= 9 && hours < 12) {
        properTime = "9am-12pm";
      } else if (hours >= 12 && hours < 15) {
        properTime = "12pm-3pm";
      } else if (hours >= 15 && hours < 18) {
        properTime = "3pm-6pm";
      } else if (hours >= 18 && hours < 21) {
        properTime = "6pm-9pm";
      } else if (hours >= 21 && hours < 24) {
        properTime = "9pm-12am";
      }

      console.log("PROPER TIME", properTime);

      return properTime;
    },

    getCurrentDayOfTheWeek() {
      const now = new Date();

      const date = now.getUTCDay();

      var gsDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

      return gsDayNames[date];
    },

    getPaymentMode(mode) {
      let paymentMode;

      if (mode == "CHECKOUT") {
        paymentMode = "Checkout";
      } else if (mode == "PAYPALSUB") {
        paymentMode = "Paypal";
      } else {
        paymentMode = mode;
      }

      return paymentMode;
    },

    getPaymentErrorType(type) {
      let errorType;

      if (type == "APPROVAL_PENDING") {
        errorType = "User";
      } else {
        errorType = "Gateway";
      }

      return errorType;
    },

    getCurrentDisplayLanguage() {
      const language = localStorage.getItem("setDisplayLanguageCode");

      let displayLang;

      if (language == "eng") {
        displayLang = "English";
      } else if (language == "ara") {
        displayLang = "Arabic";
      }

      return displayLang;
    },

    getLanguageForSubtitleAndAudio(text) {
      let lang;

      if (text == "العربية") {
        lang = "Arabic";
      } else if (text == "التركية") {
        lang = "Turkish";
      } else if (text == "الروسية") {
        lang = "Russian";
      } else if (text == "الهندية") {
        lang = "Hindi";
      } else if (text == "الإنجليزية") {
        lang = "English";
      } else if(text == "الكازاخستانية") {
        lang = "Kazakh";
      } else if(text == "الأوزبكية") {
        lang = "Uzbek";
      } else if(text == "الكورية") {
        lang = "Korean";
      } else if(text == "صينى") {
        lang = "Chinese";
      }

      return lang;
    },

    validateAgeForProfile(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;

    },
    
    throttleFunction(func, delay) {
      // Previously called time of the function
      let prev = 0;
      return (...args) => {
        // Current called time of the function
        let now = new Date().getTime();
  
        // If difference is greater than delay call
        // the function again.
        if (now - prev > delay) {
          prev = now;
  
          // "..." is the spread operator here
          // returning the function with the
          // array of arguments
          return func(...args);
        }
      };
    },

    debounceFunction(func, timeout = 1000){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    getCurrentLanguageUrlBase: function() {
      let lang = localStorage.getItem("setDisplayLanguageCode");
      let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

      if (lang === defaultLanguage) {
        return null;
      }
      for(let index = 0; index < supportedLanguages.length; index ++){
        let languageItem = supportedLanguages[index];
        if(lang === languageItem){
          return languageItem;
        }
      }
      return 'en';
    },
    getCurrentProfile: () => {
      const currentProfile = localStorage.getItem('subscriberProfileDetails') ? JSON.parse(localStorage.getItem('subscriberProfileDetails')) : '';
      try {
        const profileList = localStorage.getItem('profile-list') ? JSON.parse(localStorage.getItem('profile-list')) : '';
        for (let pro in profileList) {
          if (profileList[pro].profileid == currentProfile.profileid) {
            return profileList[pro];
          }
        }
      } catch(e) {
        return currentProfile
      }
     
    },
    isContentPlayableParentalLock: (currentProfile, ageRating) => {
      let isenabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;

      if (!currentProfile || !isenabled) return true;

      const ageRatingList = {
        'U': ['U'],
        'U/A 7+': ['U', '7+'],
        'U/A 12+': ['U', 'U/A 7+', 'U/A 12+'],
        'U/A 13+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+'],
        'U/A 16+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+'],
        'A': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+', 'A'],
        'U/A 18+': ['U', 'U/A 7+', 'U/A 12+', 'U/A 13+', 'U/A 16+', 'A', '18+']
      };
    
      for (let rating in ageRatingList) {
        if (rating == currentProfile.pgratting) {
           return ageRatingList[rating].includes(ageRating)
      
        }
      }
    },
    spacialCharEncoding(string) {
      var i = string.length,
       savedContented = [];

      while (i--) {
          var iC = string[i].charCodeAt();
          if ((iC >= 48 && iC <= 57) || (iC >= 97 && iC <= 122) || (iC >= 65 && iC <= 90)) {
            savedContented[i] = (string[i]).toLowerCase();
          } else if (iC == 32) {
            savedContented[i] = '-';
          } else {
            savedContented[i] = '';
          }
      }
      return savedContented.join('');
    },
    getCurrency (currency) {

      const currenciesObject = [
        { symbol: '$', currency: 'USD' },  // Dollar sign
        { symbol: 'AED', currency: 'AED' }, // Emirati Dirham symbol
        { symbol: 'AUD', currency: 'AUD'  },  // Dollar sign (same as USD)
        { symbol: '₹', currency: 'INR'  },  // Indian Rupee symbol
    ];

      // Find the currency object that matches the plan currency
      const currencyObj = currenciesObject.find(item => item.currency === currency);

      const symbol = currencyObj ? currencyObj.symbol : currency; // Fallback to dollar sign

      return symbol
    }
    
  },
};

export default Utility;
